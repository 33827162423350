<template>
  <div>
    Socket test - watch console logs
    <button @click="test">start test</button>
  </div>
</template>

<script>
export default {
  name: "SocketLoadTester",
  mounted() {

  },
  methods: {
    test() {
      const { io } = require("socket.io-client");

      const URL = process.env.VUE_APP_SOCKET_SERVER;
      const MAX_CLIENTS = 1000;
      const POLLING_PERCENTAGE = 0.05;
      const CLIENT_CREATION_INTERVAL_IN_MS = 10;
      const EMIT_INTERVAL_IN_MS = 1000;

      let clientCount = 0;
      let lastReport = new Date().getTime();
      let packetsSinceLastReport = 0;

      const createClient = () => {
        // for demonstration purposes, some clients stay stuck in HTTP long-polling
        const transports =
            Math.random() < POLLING_PERCENTAGE ? ["polling"] : ["polling", "websocket"];

        const socket = io(URL, {
          transports,
        });

        setInterval(() => {
          socket.emit("client to server event");
        }, EMIT_INTERVAL_IN_MS);

        socket.on("server to client event", () => {
          packetsSinceLastReport++;
        });

        socket.on("disconnect", (reason) => {
          console.log(`disconnect due to ${reason}`);
        });

        if (++clientCount < MAX_CLIENTS) {
          setTimeout(createClient, CLIENT_CREATION_INTERVAL_IN_MS);
        }
      };

      createClient();

      const printReport = () => {
        const now = new Date().getTime();
        const durationSinceLastReport = (now - lastReport) / 1000;
        const packetsPerSeconds = (
            packetsSinceLastReport / durationSinceLastReport
        ).toFixed(2);

        console.log(
            `client count: ${clientCount} ; average packets received per second: ${packetsPerSeconds}`
        );

        packetsSinceLastReport = 0;
        lastReport = now;
      };

      setInterval(printReport, 5000);
    }
  }
}
</script>

<style scoped>

</style>
