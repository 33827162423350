<template>
  <div class="home">
    <div v-if="showZoom">
      <ZoomSDKComponent v-if="showZoom"/>
      <div class="container">
        {{ test }}
      </div>
      <draggable :list="list1"
                 group="test">
        <div v-for="l in list1" :key="`l-${l}`">{{ l }}</div>
      </draggable>
      <draggable :list="list2"
                 group="test">
        <div v-for="l in list2" :key="`ld-${l}`">{{ l }}</div>
      </draggable>
    </div>
    <SocketLoadTester/>
<!--    <dotlottie-player v-if="loadedScript" src="https://lottie.host/bc246678-e355-4771-abf6-75bdb5201305/uNWYsrmITf.json" background="transparent" speed="1" style="width: 900px; height: 900px" direction="1" mode="normal" autoplay></dotlottie-player>-->
    <lottie-player v-if="loadedScript"
                   ref="lottie"
                   src="https://lottie.host/bc246678-e355-4771-abf6-75bdb5201305/uNWYsrmITf.json"
                   background="transparent"
                   speed="1"
                   loop
                   style="width: 900px; height: 900px"
                   direction="1"
                   mode="normal">
    </lottie-player>
    <div @click="$refs.lottie.play()">asdf</div>
  </div>
</template>

<script>

import ZoomSDKComponent from "../parts/class/ZoomSDKComponent"
import draggable from 'vuedraggable'
import SocketLoadTester from "../parts/class/SocketLoadTester.vue"
import { loadScript } from "vue-plugin-load-script"


export default {
  components: {
    SocketLoadTester,
    ZoomSDKComponent,
    draggable
  },
  data() {
    return {
      test: "test",
      showZoom: false,
      list1: [
        'a','b','c'
      ],
      list2: [
        '1','2','3'
      ],
      loadedScript: false
    }
  },
  async mounted() {
    // await loadScript(`https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs`)
    await loadScript(`https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js`)
    this.loadedScript = true
  },
}
</script>


