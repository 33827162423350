<template>
  <div>
    <button @click="initiate">initiate</button>
    <div id="meetingSDKElement" style="width: 300px; height: 300px;"></div>
  </div>
</template>

<script>
import ZoomMtgEmbedded from "@zoomus/websdk/embedded"
import {ZoomService} from "../../../services/api.service"

export default {
  name: "ZoomSDKComponent",
  data() {
    return {
      zoomClient: null,
      meetingNumber: '87993145462',
      role: this.$route.query.host == 1 ? 1 : 0
    }
  },
  mounted() {
    this.zoomClient = ZoomMtgEmbedded.createClient()
    let meetingSDKElement = document.getElementById('meetingSDKElement')
    this.zoomClient.init({ zoomAppRoot: meetingSDKElement, language: 'en-US' })
  },  
  methods: {
    async initiate() {
      let { jwt, sdkKey } = await this.getJWT()
      await this.startMeeting(jwt, sdkKey)
    },
    async getJWT() {
      const res = await ZoomService.getJWT({
        meetingNumber: this.meetingNumber,
        role: this.role
      })
      const data = res.data.data
      return { jwt: data.jwt, sdkKey: data.sdkKey }
    },
    async startMeeting(jwt, sdkKey) {
      let data = {
        'sdkKey':         sdkKey,
        'signature':      jwt,
        'meetingNumber':  this.meetingNumber,
        // 'userId':       'yBuk-P6oS8GnFgpJURY5Pg',
        // 'userName':       this.$route.query.host == 1 ? 'host user' : 'test user',
        // 'userEmail':      'timofey.khmelev@gmail.com',
        'password':       '9wkYFX',
        // 'tk':             ''
      }
      console.log(data)
      this.zoomClient.join(data)
    }
  }
}
</script>

<style scoped>

</style>